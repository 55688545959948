import {
    Button,
    Card,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Fab,
    ListItem,
    ListItemText,
    makeStyles,
    Typography
} from '@material-ui/core';
import React, {useEffect, useRef, useState} from 'react';
import {Add} from '@material-ui/icons';
import PropTypes from 'prop-types';
import {inspectionService} from '../services/InspectionService';
import ChooseMeasuringDevice from './ChooseMeasuringDevice';
import NewInspection from './NewInspection';
import {authService} from "../services/AuthService";
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import 'animate.css/animate.css'
import '../css/animations.css'
import Inspection from "../services/dto/Inspection";
import {ConfirmDialog} from "./ConfirmDialog";

const useStyles = makeStyles((theme) => ({
    card: {
        margin: theme.spacing(2),
        transition: 'all 1s',
    },
    isNew: {
        borderColor: "red",
        borderWidth: 2,
        animation: "pulse 1s infinite"
    },
    loaderRoot: {
        display: 'flex',
        flexFlow: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        flexGrow: 1
    },
    noInspectionsMessage: {
        textAlign: 'center',
        padding: '2rem 1rem'
    }
}));

function OpenInspection(props) {
    const [isInspectionDialogShown, setInspectionDialogShown] = useState(false);
    const [inspections, setInspections] = useState([]);
    const [selectedInspection, setSelectedInspection] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [chooseMeasuringDeviceOpen, setChooseMeasuringDeviceOpen] = useState(false);
    const [newInspection, setNewInspection] = useState(null);
    const [highlightedInspection, setHighlightedInspection] = useState(null);

    const [showInspectionConfirmDialog, setShowInspectionConfirmDialog] = useState(false);

    const listBottomRef = useRef(null);
    const classes = useStyles();

    useEffect(() => {
        let isSubscribed = true;
        setIsLoading(true);
        inspectionService.loadInspections().then((inspections) => {
            if (!isSubscribed) {
                return;
            }
            setInspections(inspections);
            setIsLoading(false);
        });
        return () => isSubscribed = false;
    }, [newInspection]);

    const selectInspection = (inspection) => {
        setSelectedInspection(inspection);
        console.log('Einrichtungsarts', inspection);
        inspection.otherInspections = inspections.filter((value: Inspection) => {
            return (inspection.customer.name === value.customer.name
                && value.facility && inspection.facility && value.facility.name
                && value.facility?.name === inspection.facility?.name && inspection.facility.facilityTypes.length > 0)
        });
        inspection.otherInspections.forEach(value => {
            value.otherInspections = inspection.otherInspections;
        })

        if(inspection.chiefInspector.id !== authService.user.id) {
            setShowInspectionConfirmDialog(true)
        } else {
            setChooseMeasuringDeviceOpen(true);
        }

    }

    const handleCancelled = () => {
        setSelectedInspection(null);
        setChooseMeasuringDeviceOpen(false);
    }

    const handleSelected = (measuringDevice) => {
        setChooseMeasuringDeviceOpen(false);
        props.onInspectionSelected(selectedInspection, measuringDevice);
    }

    if (isLoading) {
        return (<div className={classes.loaderRoot}><CircularProgress/></div>);
    }

    const didCreateInspection = (inspection) => {
        setNewInspection(inspection);
        setHighlightedInspection(inspection);
        setInspectionDialogShown(false);


        setTimeout(function () {
            listBottomRef.current.scrollIntoView( {
                behavior: "smooth",
                block:    "end",
            });

            setTimeout(function () {
                setHighlightedInspection(null);
            }, 3000);
        }, 500);

    }

    return (
        <div>
            <div>
                {inspections.map((inspection) => {
                    const title = inspection.facility.nameWithCustomerAndFacilityType;
                    const subtitle = inspection.facility.address + ` (Start: ${inspection.begin.toLocaleString()})`;

                    return (
                        <Card key={inspection.id} className={`${classes.card} ${highlightedInspection && (highlightedInspection.id === inspection.id) ? classes.isNew : ''}`} variant={'outlined'}>
                            <ListItem button onClick={() => selectInspection(inspection)}
                                      selected={inspection && selectedInspection && selectedInspection.id === inspection.id}>
                                <ListItemText primary={title} secondary={subtitle}/>
                            </ListItem>
                        </Card>
                    );
                })}
                {inspections.length === 0 && <Typography className={classes.noInspectionsMessage}>Aktuell gibt es keine gestarteten Prüfungen.</Typography>}
                <div ref={listBottomRef}  style={{backgroundColor: 'transparent', height: '100px'}}>

                </div>
                <Fab color="primary" aria-label="Neue Prüfung anlegen" className={'bottomRight'}
                     onClick={() => setInspectionDialogShown(true)}>
                    <Add/>
                </Fab>
            </div>
            <ChooseMeasuringDevice onSelected={handleSelected} onCancelled={handleCancelled}
                                   open={chooseMeasuringDeviceOpen}/>
            <NewInspectionDialog open={isInspectionDialogShown} onInspectionCreated={didCreateInspection}/>
            <ConfirmDialog open={showInspectionConfirmDialog} title={`Teilnahme an Prüfung bestätigen`}
                           message={<>Diese Prüfung wird geleitet von <b>{selectedInspection?.chiefInspector?.fullName}</b>.
                           <br /> Wollen Sie diese Prüfung öffnen?</>} handleConfirm={(confirm) => {
                setShowInspectionConfirmDialog(false)
                if(!confirm) {
                    setSelectedInspection(null)
                } else {
                    setChooseMeasuringDeviceOpen(true)
                }
            }}></ConfirmDialog>
        </div>
    );
}

function NewInspectionDialog(props) {
    const styleStep1 = makeStyles({
        dialogPaper: {
            minWidth: '400px'
        },
    });
    const classes = styleStep1();

    const [inspection, setInspection] = useState();
    const [isCreating, setCreating] = useState(false)

    const create = () => {
        if (!inspection) {
            return;
        }

        setCreating(true);
        inspectionService.createInspection(inspection.facility, authService.user, inspection.begin)
            .then((inspection) => {
                setCreating(false);
                props.onInspectionCreated(inspection);
            });
    }


    const inspectionCreated = (newInspection) => {
        setInspection(newInspection);
    }

    const handleClose = () => {
        props.onInspectionCreated(null);
    }

    return (
        <div>
            <Dialog
                open={props.open}
                onClose={handleClose}
                // PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
                classes={{ paper: classes.dialogPaper }}
            >
                <BlockUi tag="div" blocking={isCreating}>
                    <DialogTitle id="draggable-dialog-title">
                        Neue Prüfung anlegen
                    </DialogTitle>
                    <DialogContent>
                        <NewInspection onInspectionCreated={inspectionCreated} onAborted={handleClose}/>
                    </DialogContent>
                    <DialogActions>
                        <Button variant={"contained"} disabled={!inspection} onClick={create} color="primary">
                            Speichern
                        </Button>
                    </DialogActions>
                </BlockUi>
            </Dialog>
        </div>
    )
}

NewInspectionDialog.propTypes = {
    onInspectionCreated: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired
}

OpenInspection.propTypes = {
    onInspectionSelected: PropTypes.func.isRequired
}

export default OpenInspection;