import {
    Button,
    CircularProgress,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    makeStyles
} from "@material-ui/core";
import {Cancel, CheckCircle} from "@material-ui/icons";
import PropTypes from 'prop-types';
import {useEffect, useState} from "react";
import baseDataService from '../services/BaseDataService';

const useStyles = makeStyles(() => ({
    root: {
        minWidth: '15rem',
        maxWidth: '50%',
        alignSelf: 'center',
        textAlign: 'center'
    }
}));

function Loader(props) {
    const loadingStates = {
        loading: 1,
        finished: 2,
        error: 3
    };
    const [retry, setRetry] = useState(false);
    const [dataLoaders, setDataLoaders] = useState({
        dutCategories: {label: 'Betriebsmittel', loader: baseDataService.loadDutCategories},
        notices: {label: 'Hinweistexte', loader: baseDataService.loadNotices},
        measuringDevices: {label: 'Messgeräte', loader: baseDataService.loadMeasuringDevices}
    });
    const classes = useStyles();

    const load = () => {
        setRetry(false);

        const newState = {...dataLoaders};
        for (const key in dataLoaders) {
            const dataLoader = dataLoaders[key];
            if (dataLoader.state === loadingStates.finished) {
                continue;
            }

            newState[key].state = loadingStates.loading;
        }
        setDataLoaders(newState);

        const promises = [];
        for (const key in dataLoaders) {
            const dataLoader = dataLoaders[key];
            if (dataLoader.state === loadingStates.finished) {
                continue;
            }

            promises.push(dataLoader.loader().then(() => {
                const newState = {...dataLoaders};
                newState[key].state = loadingStates.finished;
                setDataLoaders(newState);
                return true;
            }).catch((error) => {
                const newState = {...dataLoaders};
                newState[key].state = loadingStates.error;
                setDataLoaders(newState);
                throw error;
            }));
        }
        Promise.all(promises).then(() => {
            setTimeout(props.onLoaded, 250);
        }).catch((error) => {
            setRetry(true);
            console.error(error);
        });
    }
    useEffect(() => {
        load();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const ProgressIndicator = (props) => {
        switch (props.state) {
            case loadingStates.loading:
                return (<CircularProgress/>);
            case loadingStates.finished:
                return (<CheckCircle style={{color: 'green'}}/>);
            case loadingStates.error:
                return (<Cancel color={'error'}/>);
            default:
                return '';
        }
    }

    const listItem = ({label, state}) => {
        return (
            <ListItem key={label}>
                <ListItemText primary={label}/>
                <ListItemSecondaryAction>
                    <ProgressIndicator state={state}/>
                </ListItemSecondaryAction>
            </ListItem>
        );
    };

    return (
        <div className={classes.root}>
            <List>
                {Object.keys(dataLoaders).map((key) => listItem(dataLoaders[key]))}
            </List>
            {retry && (<Button onClick={load} variant={'contained'} color={'primary'}>Erneut versuchen</Button>)}
        </div>
    );
}

Loader.propTypes = {
    onLoaded: PropTypes.func.isRequired
}

export default Loader;