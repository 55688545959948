import axios from "axios";
import User from './dto/User';

class AuthService {
    /**
     * @type {string|null}
     */
    authToken = localStorage.getItem('authToken') || null;
    /**
     * @type {User|null}
     */
    user = localStorage.getItem('user') ? User.fromResponse(JSON.parse(localStorage.getItem('user'))) : null;



    logout() {
        this.authToken = null;
        this.user = null;
        localStorage.removeItem('authToken');
        localStorage.removeItem('user');
    }

    async login(email, password) {
        if (this.authToken) {
            return;
        }

        const {data} = await axios.post('/auth/local', {
            identifier: email,
            password: password
        });
        this.authToken = data.jwt;
        this.user = User.fromResponse(data.user);
        localStorage.setItem('authToken', this.authToken);
        localStorage.setItem('user', JSON.stringify(data.user));
    }
}


export let authService = new AuthService();