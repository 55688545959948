import {authService} from '../AuthService';
import InspectionItemTask, {TASK_ACTIONS, TASK_STATUS} from "./InspectionItemTask";
import localforage from "localforage";
import PubSub from 'pubsub-js'
import Inspection from "../dto/Inspection";
import {SyncQueue} from "./SyncQueue";
import {inspectionService} from "../InspectionService";
import InspectionItem from "../dto/InspectionItem";

const generateChannelID = (inspectionID) => {
    return 'SYNC_SERVICE_' + inspectionID;
}

class SyncService {

    currentInspection : Inspection
    currentTaskQueue : []

    constructor() {
        this.currentTaskQueue = [];
    }

    changeInspection(inspection: Inspection) {
        if(this.currentInspection) {
            this.writeTaskQueue();
        }
        this.currentInspection = inspection;
        if(inspection) {
            this.currentTaskQueue = [];
            return localforage.getItem(`inspectionTasks-${inspection.id}`).then((tasks) => {
                if (tasks) {
                    let parsedTasks = [];
                    for (const task of tasks) {
                        parsedTasks.push(InspectionItemTask.fromResponse(task));
                    }
                    this.submitSyncTasks(parsedTasks);
                } else {
                    this.sendStatusUpdate();
                }
            });
        }
    }

    /**
     * @param dutNumber
     * @returns {*|boolean|InspectionItemTask}
     */
    findInspectionItem(dutNumber) {
        for (let i = 0; i < this.currentInspection.items.length; i++) {
            let item = this.currentInspection.items[i];
            //TODO: Hier nochmal prüfen ob dutnumber wirklich immer ein Number ist!
            if(item.dutNumber === dutNumber) {
                return new InspectionItemTask(item);
            }
        }
        for (let i = 0; i < this.currentTaskQueue.length; i++) {
            let item = this.currentTaskQueue[i].inspectionItem;
            //TODO: Hier nochmal prüfen ob dutnumber wirklich immer ein Number ist!
            if(item.dutNumber === dutNumber) {
                return this.currentTaskQueue[i];
            }
        }
        return false;
    }

    debugOutputTaskStorage() {
        return localforage.getItem(`inspectionTasks-${this.currentInspection.id}`).then((tasks) => {
            alert(JSON.stringify(tasks))
        });
    }

    findDuplicateDutNumber(task: InspectionItemTask) {
        for (let i = 0; i < this.currentInspection.items.length; i++) {
            let item = this.currentInspection.items[i];
            //TODO: Hier nochmal prüfen ob dutnumber wirklich immer ein Number ist!
            if(item.dutNumber === task.inspectionItem.dutNumber) {
                return true;
            }
        }
        for (let i = 0; i < this.currentTaskQueue.length; i++) {
            let item = this.currentTaskQueue[i].inspectionItem;
            //TODO: Hier nochmal prüfen ob dutnumber wirklich immer ein Number ist!
            if(item.dutNumber === task.inspectionItem.dutNumber && task !== this.currentTaskQueue[i]) {
                return true;
            }
        }
        return false;
    }


    writeTaskQueue() {
        let serializedArray = [];
        for (const task of this.currentTaskQueue) {
            serializedArray.push(task.serialize());
        }
        return localforage.setItem(`inspectionTasks-${this.currentInspection.id}`, serializedArray)
    }

    removeItemFromQueue(inspectionItem : InspectionItem) {
        let indexToRemove = -1;
        for (let i = 0; i < this.currentTaskQueue.length; i++) {
            let task = this.currentTaskQueue[i];
            if(task.inspectionItem.dutNumber === inspectionItem.dutNumber) {
                indexToRemove = i;
                break;
            }
        }
        if(indexToRemove > -1 ) {
            this.currentTaskQueue.splice(indexToRemove,1);
        }
    }

    removeTasksFromQueue(tasks:InspectionItemTask[]) {
        tasks = [].concat(tasks || []);
        for (let task of tasks) {
            this.removeTaskFromQueue(task);
            if(task.inspectionItem) {
                this.removeItemFromQueue(task.inspectionItem)
            }
        }
        this.sendStatusUpdate();
        return this.writeTaskQueue()
    }

    removeTaskFromQueue(taskToRemove : InspectionItemTask) {
        let indexToRemove = -1;
        for (let i = 0; i < this.currentTaskQueue.length; i++) {
            let task = this.currentTaskQueue[i];
            if(task.id === taskToRemove.id) {
                indexToRemove = i;
                break;
            }
        }
        if(indexToRemove > -1 ) {
            this.currentTaskQueue.splice(indexToRemove,1);
        }
    }

    allNonPassedItems() {
        let items = this.currentInspection?.items.filter(function(item : InspectionItem) {
            console.log(item.recommendation)
            return !item.passed || (item.action && item.action.length > 0) || (item.recommendation && item.recommendation.length > 0);
        });
        if(!items) {
            items = [];
        }
        let inspectionItemTasks = items.map((item) => {
            return new InspectionItemTask(item);
        })
        //TODO: gelöschte Items wieder rausfiltern!!!
        inspectionItemTasks = inspectionItemTasks.concat(this.currentTaskQueue);
        return inspectionItemTasks.sort((a, b) => ('' + a.inspectionItem.updatedAt).localeCompare(b.inspectionItem.updatedAt)).reverse();
    }

    lastNItemsOfCurrentInspector(n) {
        let items = this.currentInspection?.items.filter(function(item) {
            return item.inspector.id === authService.user.id;
        });

        if(!items) {
            items = [];
        }

        items = items.slice(-n);

        let inspectionItemTasks = items.map((item) => {
            return new InspectionItemTask(item);
        })
        //TODO: gelöschte Items wieder rausfiltern!!!
        inspectionItemTasks = inspectionItemTasks.concat(this.currentTaskQueue);
        return inspectionItemTasks.sort((a, b) => ('' + a.inspectionItem.updatedAt).localeCompare(b.inspectionItem.updatedAt)).reverse().slice(0, n);
    }

    retryErrorTasks() {
        for (const task of this.currentTaskQueue) {
            if(task.status === TASK_STATUS.PROBLEM) {
                task.status = TASK_STATUS.WAITING;
                SyncQueue.enqueue(this.generateInspectionItemTask(task));
                this.sendStatusUpdate(task);
            }
        }
    }

    submitSyncTasks(pTasks:InspectionItemTask[]) {
        let tasks = [].concat(pTasks || []);
        this.removeTasksFromQueue(tasks).then(() => {
            for (const task of tasks) {

                if(task.action === TASK_ACTIONS.DELETE || task.action === TASK_ACTIONS.UPDATE) {
                    this.currentInspection.removeItem(tasks[0].inspectionItem);
                }
                if(task.action !== TASK_ACTIONS.DELETE || task.inspectionItem.id) {
                    this.currentTaskQueue.push(task);
                }
            }
            this.writeTaskQueue().then(() => {
                this.sendStatusUpdate();
                for (const task of tasks) { //TODO: mit o.s. Loop zusammenführen!
                    if(task.action !== TASK_ACTIONS.DELETE || task.inspectionItem.id) {
                        SyncQueue.enqueue(this.generateInspectionItemTask(task));
                    }
                }
            })
        });
    }

    generateInspectionItemTask(task : InspectionItemTask) {
        return () => {
            return Promise.resolve(task).then(() => {
                task.status = TASK_STATUS.IN_PROGRESS;
            }).then(() => {
                if(task.action === TASK_ACTIONS.CREATE) {
                    return inspectionService.addInspectionItem(task, this.currentInspection);
                } else if(task.action === TASK_ACTIONS.DELETE) {
                    return inspectionService.deleteInspectionItem(task, this.currentInspection);
                } else if(task.action === TASK_ACTIONS.UPDATE) {
                    return inspectionService.patchInspectionItem(task, this.currentInspection);
                }
            }).then((newInspectionItem: InspectionItem) => {
                if(task.action === TASK_ACTIONS.CREATE || task.action === TASK_ACTIONS.UPDATE) {
                    task.status = TASK_STATUS.PERSISTENT;
                    task.inspectionItem = newInspectionItem;
                    this.currentInspection.items.push(newInspectionItem);
                }
                return this.removeTasksFromQueue(task);
            }).then(()=> {
                this.sendStatusUpdate(task);
            }).catch(() => {
                task.status = TASK_STATUS.PROBLEM;
                setTimeout(() => {
                    this.sendStatusUpdate(task);
                }, 500)
            })
        }
    }

    sendStatusUpdate(currentTask = null) {
        PubSub.publish(generateChannelID(this.currentInspection.id),this.generateStatusUpdate(currentTask));
    }

    generateStatusUpdate(currentTask = null) {
        let errorTasks = this.currentTasksWithError();
        let syncUpdate = new SyncStatusUpdate((errorTasks.length === 0 && currentTask?.status !== TASK_STATUS.PROBLEM), this.currentTaskCount(), this.currentTasksWithError(), currentTask);
        return syncUpdate;
    }

    subscribeForOnlineStatusUpdates(callback) {
        console.log('subscribers: ' + PubSub.sub)
        let token =  PubSub.subscribe(generateChannelID(this.currentInspection?.id), callback);
        return token
    }

    unsubscribeFromOnlineStatusUpdates(token) {
        PubSub.unsubscribe(token)
    }

    currentTasksWithError() {
        console.log('in task with errors: ' + JSON.stringify(this.currentTaskQueue))
        return this.currentTaskQueue.filter((task:InspectionItemTask) => {
           //alert(task.status);
            return task.status === TASK_STATUS.PROBLEM
        });
    }

    currentTaskCount() {
        return this.currentTaskQueue.length;
    }
}

    export class SyncStatusUpdate {
        constructor(online, outstanding, tasksWithError, currentTask) {
            this._outstanding = outstanding;
            this._online = online;
            this._currentTask = currentTask;
            this._tasksWithError = tasksWithError;
        }

        get online() {
            return this._online;
        }

        get outstanding() {
            return this._outstanding;
        }

        get currentTask() {
            return this._currentTask;
        }

        get tasksWithError() {
            return this._tasksWithError;
        }
    }


let syncService = new SyncService();
export default syncService;
