import axios from "axios";
import { Buffer } from "buffer";

export default class Media {

    /**
     * @param id {number}
     * @param url {string}
     */
    constructor(id, url) {
        this._id = id;
        this._url = url;
        this._data = null;
        this.getBase64(url)
    }

    static fromResponse(data) {
        return new Media(data.id, process.env.REACT_APP_API_BASEURL + data.url);
        // return new Media(data.id, 'https://backend.app-bfa-berlin.com' + data.url);
    }

    getBase64(url) {
        return axios
            .get(url, {
                responseType: 'arraybuffer'
            })
            .then(response => Buffer.from(response.data, 'binary').toString('base64'))
            .then(image => {
                this._data = image;
            })
    }

    /**
     * @returns {number}
     */
    get id() {
        return this._id;
    }

    /**
     * @returns {string}
     */
    get url() {
        return this._url;
    }

    get data(): string {
        return this._data;
    }

    set data(value: string) {
        this._data = value;
    }

    image() {
        if(this._data) {
            return 'data:image/svg+xml;base64,' + this._data;
        } else {
            return this.url
        }
    }

}